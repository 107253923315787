import React from 'react'
import About from './About';
import Home from './Home';
import Information from './Information';
import Treatment from './Treatment';
import Maps from './Maps';
import Footer from './Footer';
import Reviews from './Reviews';

const layout = () => {
  return (
    <div className='main'>

      <Home />
      <About />
      <Information />
      <Treatment />
      <Maps />
      <Reviews/>
      <Footer />



    </div>
  )
}

export default layout