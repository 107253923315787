import React from 'react'
import munsir from '../img/Munsir.jpg'

const About = () => {
  return (

    <div className='about'>


      <div className='about2'>
        <h2> WELCOME TO DR. UMAR MUSHIR  <span>MIND'S EYE
        NEURO PSYCHIATRY CLINIC</span></h2>
        <p>"Specialised Neuro Psychiatry Services Depression Anxiety Bipolar Disorder Alcohol and Tobacco De Addiction Schizophrenia Obsessive Compulsive Disorder Sexual Disorders Old Age Psychiatric Disorders Childhood Psychiatric Disorders"
        </p>
        <p>Mind's Eye NeuroPsychiatry Clinic, led by Dr. Umar Mushir, offers a comprehensive range of services to address various mental health concerns. With a focus on Personality Disorder Treatment, Schizophrenia Treatment, and Adult Counseling, the clinic provides personalized care to individuals seeking support for their mental well-being</p>

      </div>

      <div className='about2'>
        <img src={munsir} />
      </div>

    </div>

  )
}

export default About