import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import treat1 from '../img/deprassion.jpg';
import treat2 from '../img/PersonalDisorder.jpg';
import treat3 from '../img/alcohol.jpg';
import treat4 from '../img/obsesivedisorder.jpg';
import treat5 from '../img/drug.jpg';

const Treatment = () => {
  const treatments = [
    {
      title: 'Depression',
      description: 'Depression is a common and serious mental health condition that negatively affects how you feel, the way you think, and how you act. It is characterized by persistent feelings of sadness and a lack of interest or pleasure in previously enjoyed activities. Depression can lead to various emotional and physical problems and can decrease a person\'s ability to function at work and at home.',
      image: treat1,
    },
    {
      title: 'Personality Disorder',
      description: 'Personality disorders are a group of mental health conditions characterized by long-lasting, rigid patterns of thought, behavior, and emotion that deviate significantly from cultural expectations and cause distress or impair functioning. These disorders typically begin in adolescence or early adulthood and can affect multiple areas of a person\'s life, including relationships, work, and social interactions.',
      image: treat2,
    },
    {
      title: 'Alcoholism',
      description: 'Alcoholism, also known as alcohol use disorder (AUD), is a chronic disease characterized by an inability to control alcohol consumption despite negative consequences. It involves physical and psychological dependence on alcohol and can lead to serious health problems, interpersonal issues, and difficulties in daily functioning.',
      image: treat3,
    },
    {
      title: 'Obsessive Disorder',
      description: 'Obsessive-compulsive disorder (OCD) is a mental health condition characterized by persistent, unwanted thoughts (obsessions) and repetitive behaviors or mental acts (compulsions) that individuals feel compelled to perform. These symptoms can significantly interfere with daily functioning and cause considerable distress.',
      image: treat4,
    },
    {
      title: 'Drug Addiction',
      description: 'Drug addiction, also known as substance use disorder, is a chronic disease characterized by compulsive or uncontrollable drug-seeking behavior and use despite harmful consequences. It involves changes in brain function that affect self-control and the ability to resist the urge to use drugs. Drug addiction can lead to a range of physical, emotional, and social problems.',
      image: treat5,
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div className='treats'>
      <br />
      <center><h1>TREATMENTS</h1></center>
      <div className='treatment-slider'>
        <Slider {...sliderSettings}>
          {treatments.map((treatment, index) => (
            <div key={index}>
              <img src={treatment.image} alt={treatment.title} />
              <div className='content'>
                <h1>{treatment.title}</h1>
                <p>{treatment.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <br />
    </div>
  );
};

export default Treatment;
