import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './components/Media.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Navbar from './components/Navbar';
import Layout from './components/Layout'; // Assuming Layout.jsx or Layout.js
import Section from './components/Section';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Treatment from './components/Treatment';
import Treatments2 from './components/Treatments2';

function App() {
  return (
   <div className='main'>
     <Router>
      <Navbar />
      <Routes>
        <Route path="*" element={<Layout />} />
        <Route path="/Section" element={<Section />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Treatments2" element={<Treatments2 />} />
      </Routes>
    </Router>
   </div>
  );
}

export default App;


