import React from 'react';
import Footer from './Footer';


import gal1 from '../img/board.jpg';
import gal2 from '../img/Munsir.jpg';
import gal3 from '../img/trae2.jpg';
import gal4 from '../img/trat3.jpg';
import gal5 from '../img/treat1.jpg';
import gal6 from '../img/tret6.jpg';
import gal7 from '../img/trat5.jpg';
import gal8 from '../img/Munsir.jpg';

const Gallery = () => {
  return (
    <>
      <div className="gallery-container">
        <h2>Gallery</h2>
        <div className="image-grid">
          <img src={gal1} alt="Gallery Image 1" className="gallery-image" />
          <img src={gal2} alt="Gallery Image 2" className="gallery-image" />
          <img src={gal3} alt="Gallery Image 3" className="gallery-image" />
          <img src={gal4} alt="Gallery Image 4" className="gallery-image" />
          <img src={gal5} alt="Gallery Image 5" className="gallery-image" />
          <img src={gal6} alt="Gallery Image 6" className="gallery-image" />
          <img src={gal7} alt="Gallery Image 6" className="gallery-image" />
          <img src={gal8} alt="Gallery Image 6" className="gallery-image" />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Gallery;
