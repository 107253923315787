import React from 'react'
import { Link } from 'react-router-dom'


const Information = () => {
    return (
        <div className='information'>

            <h2>Compassionate Care and Expertise</h2>

            <h4>Dr. Umar Mushir and his team are committed to delivering compassionate care and expertise to every individual who seeks their assistance. With a focus on understanding each patient's unique circumstances, the clinic ensures that treatment plans are tailored to address specific concerns and goals</h4>


            <Link> <a href="Tel:6306574323"><button> Call Us : +91 6306574323</button></a></Link>

        </div>
    )
}

export default Information