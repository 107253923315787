import React from 'react'
import { Link } from 'react-router-dom'


const Footer = () => {
    return (
        <>
            <div className='footer'>

                <div className='foot'>
                    <h1>About</h1>
                    <p>"Specialised Neuro Psychiatry Services Depression Anxiety Bipolar Disorder Alcohol and Tobacco De Addiction Schizophrenia Obsessive Compulsive Disorder Sexual Disorders Old Age Psychiatric Disorders Childhood Psychiatric Disorders"</p>
                </div>

                <div className='foot'>

                    <h1>Useful Links</h1>

                    <p>  <Link className='links'>Home </Link></p>
                    <p>  <Link className='links'> About</Link></p>
                    <p>  <Link className='links'> Treatment</Link></p>
                    <p>  <Link className='links'> Gallery</Link></p>
                    <p>  <Link className='links'> Contact</Link></p>

                </div>

                <div className='foot'>
                    <h1>Reach Us </h1>

                    <p>18/247, Tedhi Pulia Ring Rd, Sector 18, Indira Nagar, Lucknow, Uttar Pradesh 226016</p>
                    <p> Call +91 6306574323</p>
                    <p>umar.musher@gmail.com</p>

                    <a href='https://www.facebook.com/PsychiatristDrUmar/'><i class="fa fa-facebook-square"></i> </a>
                    <a href=''><i class="fa fa-instagram"></i> </a>
                    <a href=''><i class="fa fa-youtube-play"></i> </a>
                    <a href='https://wa.me/916306574323'><i class="fa fa-whatsapp"></i> </a>

                </div>


            </div>


<div className='discription'>
<p>@2024 RESERVED  DR. UMAR MUSHIR MIND'S EYE NEURO PSYCHIATRY CLINIC    DEVELOPED & DESIGNED BY CREATIVE DIGITAL WORLD </p>

</div>



        </>
    )
}

export default Footer