import React from 'react'
import About from './About'
import Footer from './Footer'

const Section = () => {
    return (
        <div>

            <br />
            <center><h2>About Us</h2></center>
            <About />
            <Footer />


        </div>
    )
}

export default Section