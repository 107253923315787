import React, { useState, useEffect } from 'react';


// Import all review images
import review1 from '../img/rev1.jpg';
import review2 from '../img/rev2.jpg';
import review3 from '../img/rev3.png';
import review4 from '../img/rev4.jpg';
import review5 from '../img/rev5.jpg';
import review6 from '../img/rev6.jpg';
import review7 from '../img/rev7.png';
import review8 from '../img/rev8.jpg';
import review9 from '../img/rev9.jpg';
import review10 from '../img/rev10.jpg';

const Reviews = () => {
  // Array of imported images
  const reviews = [review1, review2, review3, review4, review5, review6, review7, review8, review9, review10];

  // State for tracking current slide index
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to move to the next slide
  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % reviews.length);
  };

  // Function to move to the previous slide
  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + reviews.length) % reviews.length);
  };

  // Automatic sliding
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % reviews.length);
    }, 4000); // Change slide every 3 seconds (adjust as needed)

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [currentSlide, reviews.length]);

  return (
    <div className="reviews-container">
      <h2>Our Patient Reviews</h2>
      <div className="slider">
        <button className="slide-button prev" onClick={prevSlide}>
          Prev
        </button>
        <img src={reviews[currentSlide]} alt={`Review ${currentSlide + 1}`} className="slide" />
        <button className="slide-button next" onClick={nextSlide}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Reviews;
