import React from 'react'

const Maps = () => {
  return (
    <div className='maps'>

    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14234.25728580175!2d80.9867717!3d26.8855799!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be345509a17ed%3A0x11654f93030fd619!2sDr%20Umar%20Mushir%20-%20Mind&#39;s%20Eye%20Neuro%20Psychiatry%20Clinic!5e0!3m2!1sen!2sin!4v1720589591813!5m2!1sen!2sin" width="100%" height="100%"  ></iframe>

    </div>
  )
}

export default Maps