import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo.jpg'

const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg ">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand" >
                        <b> Dr. Umar Mushir</b> <br/> <span>Mind's Eye Neuro Psychiatry Clinic</span>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" className="nav-link" onClick={handleNavItemClick}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Section" className="nav-link" onClick={handleNavItemClick}>About</Link>
                            </li>
                           
                            <li className="nav-item">
                                <Link to="/Treatments2" className="nav-link" onClick={handleNavItemClick}>Treatment</Link>
                            </li>
                        
                            <li className="nav-item">
                                <Link to="/Gallery" className="nav-link" onClick={handleNavItemClick}>Gallery</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Contact" className="nav-link" onClick={handleNavItemClick}>Contact</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
